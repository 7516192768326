import React from "react";
import { usePagination, DOTS } from "./usePagination";
import classNames from "classnames";
import "./Pagination.scss";

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
}) => {
 

  const paginationRange = structuredClone(usePagination({
    currentPage,
    totalCount,
    pageSize,
    siblingCount,
  }));


  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }


  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange?.length - 1];

  return (
    <ul
      className={classNames("pagination-container border p-0", {
        [className]: className,
      })}
    >
      <li
        className={classNames("pagination-item border-end", {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li className="pagination-item border-end dots" key={index}>
              &#8230;
            </li>
          );
        }

        return (
          <li
            className={classNames("pagination-item border-end", {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
            key={index}
          >
            {pageNumber}
          </li>
        );
      })}

      <li
        className={classNames("pagination-item", {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;
