import React, { useEffect, useState } from "react";
import TooltipButton from "../../../TooltipButton/TooltipButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import NoDataFound from "../../../NotFound/NoDataFound";
import {
  rejectingJournalsMessage,
  acceptingJournalsMessage,
  subjectCategoryMessage,
} from "../../../TooltipButton/TooltipMessage";
import * as d3 from "d3";
import { useDispatch } from "react-redux";
import {
  setGraphType,
  setManuscriptModal,
} from "../../../../Redux/Action/filter.action";
import ReactGA from "react-ga4";
import { title, toTitleCase } from "../../../Filter/Filter";
import Graphdownload from "../Graphdonwload/Graphdownload";

import "./GroupedHorizontalBar.scss";

function loadGraph(data, dispatch, journalType) {
  const chartWidth = 625,
    barHeight = 36 / data.series.length,
    groupHeight = barHeight * data.series.length,
    gapBetweenGroups = 15,
    spaceForLabels = 400,
    spaceForLegend = 200,
    marginBottom = data.series.length * gapBetweenGroups,
    marginTop = 40,
    marginRight = 20,
    radius = barHeight / 2;

  const barOpacity = "1",
    barOpacityHover = "1",
    otherBarOpacityHover = "0.2";

  // Zip the series data together (first values, second values, etc.)
  let zippedData = [];
  for (let i = 0; i < data.categories.length; i++) {
    for (let j = 0; j < data.series.length; j++) {
      zippedData.push(data.series[j].data[i]);
    }
  }

  // Color scale
  const colorScale = ["#544fc5", "#4CAF50", "#2caffe"];
  let color = d3
    .scaleOrdinal()
    .domain(
      data.series.map((d) => {
        return d.name;
      })
    )
    .range(colorScale.slice(0, data.series.length));

  let chartHeight =
    barHeight * zippedData.length + gapBetweenGroups * data.categories.length;

  if (!d3.select("#grouped_horizontal_bar > svg").empty()) {
    d3.select("#grouped_horizontal_bar > svg").remove();
  }

  let max = d3.max(zippedData);

  let x = d3
    .scaleLinear()
    .domain([0, max])
    .range([0, chartWidth - marginRight])
    .nice(3);

  let y = d3.scaleLinear().range([chartHeight + gapBetweenGroups, 0]);

  let yAxis = d3.axisLeft(y).tickFormat("").tickSize(0);

  let xAxis = d3.axisBottom(x).tickSize(7).tickSizeOuter(0).ticks(9);

  // Specify the chart area and dimensions
  let chart = d3
    .select("#grouped_horizontal_bar")
    .append("svg")

    .attr("width", spaceForLabels + chartWidth + spaceForLegend)
    .attr("height", chartHeight + marginBottom + marginTop)
    .append("g")
    .attr("transform", `translate(0,0)`);

  let group = chart.append("g").attr("transform", `translate(0,0)`);

  const seriesIndex = d3.local();
  const categoryIndex = d3.local();

  // Create bars
  let bar = group
    .selectAll("g")
    .data(zippedData)
    .enter()
    .append("g")
    .each(function (d, i) {
      seriesIndex.set(this, i % data.series.length);
      categoryIndex.set(this, Math.floor(i / data.series.length));
    })
    .attr("transform", function (d, i) {
      return (
        "translate(" +
        spaceForLabels +
        "," +
        (i * (barHeight + 1.5) +
          gapBetweenGroups * (0.5 + Math.floor(i / data.series.length))) +
        ")"
      );
    });

  // Create rectangles of the correct width
  bar
    .append("clipPath")
    .attr("id", (d, i) => {
      return "clip_bar_" + i;
    })
    .append("rect")
    .attr("fill", function (d, i) {
      return color(i % data.series.length);
    })
    .attr("class", function (d, i) {
      return "rect bar" + (i % data.series.length);
    })
    .attr("height", barHeight - 1)
    .attr("width", 0)
    .attr("x", -(radius - 1))
    .attr("rx", radius)
    .transition()
    .duration(1200)
    .attr("width", (d) => x(d) + 5 < 11 ? 11 : x(d) + 5);

  bar
    .append("rect")
    .attr("fill", function (d, i) {
      return color(i % data.series.length);
    })
    .attr("class", function (d, i) {
      return "rect bar" + (i % data.series.length);
    })
    .attr("width", 0)
    .attr("height", barHeight)
    .attr(
      "index",
      (d, i) =>
        i * (barHeight + 1.5) +
        gapBetweenGroups * (0.5 + Math.floor(i / data.series.length))
    )
    .attr("clip-path", (d, i) => `url(${"#clip_bar_" + i})`)
    .on("click", function (event, d) {
      if (journalType === "rejectingJournal") {
        if (
          data.series[seriesIndex.get(this)].name !==
          "Total Rejected Manuscripts"
        ) {
          ReactGA.event({
            category: toTitleCase(title),
            action: `Bar Graph clicked`,
            label: `s_journal= ${
              data.categories[categoryIndex.get(this)]
            }, filterStatus= totalPublication`,
          });
          dispatch(
            setGraphType({
              title: `${
                data.categories[categoryIndex.get(this)]
              } > TOTAL PUBLICATIONS`,
              graphType: {
                graphType: "journalTrend",
                s_journal: data.categories[categoryIndex.get(this)],
                filterStatus: "totalPublication",
              },
            })
          );
          dispatch(setManuscriptModal(true));
        }
      } else if (journalType === "acceptingJournal") {
        ReactGA.event({
          category: toTitleCase(title),
          action: `Bar Graph clicked`,
          label: `p_journal= ${
            data.categories[categoryIndex.get(this)]
          }, filterStatus= totalPublication`,
        });
        dispatch(
          setGraphType({
            title: `${
              data.categories[categoryIndex.get(this)]
            } > TOTAL PUBLICATIONS`,
            graphType: {
              graphType: "journalTrend",
              p_journal: data.categories[categoryIndex.get(this)],
              filterStatus: "totalPublication",
            },
          })
        );
        dispatch(setManuscriptModal(true));
      } else if (journalType === "subjectCategory") {
        if (
          data.series[seriesIndex.get(this)].name !==
          "Total Rejected Manuscripts"
        ) {
          ReactGA.event({
            category: toTitleCase(title),
            action: `Bar Graph clicked`,
            label: `s_journal= ${
              data.categories[categoryIndex.get(this)]
            }, filterStatus= totalPublication`,
          });
          dispatch(
            setGraphType({
              title: `${
                data.categories[categoryIndex.get(this)]
              } > TOTAL PUBLICATIONS`,
              graphType: {
                graphType: "journalTrend",
                subject_category: data.categories[categoryIndex.get(this)],
                filterStatus: "totalPublication",
              },
            })
          );
          dispatch(setManuscriptModal(true));
        }
      }
    })
    .on("mouseover", function (e, d) {
      d3.selectAll(".rect").style("opacity", otherBarOpacityHover);
      d3.selectAll(".bar" + seriesIndex.get(this))
        .style("opacity", barOpacityHover)
        .style("cursor", "pointer");

      let height = d3.select(this).attr("index");
      let tooltipWidth = 150;
      let tooltipHeight = 35;

      const tooltipGroup = d3
        .select("#grouped_horizontal_bar svg g")
        .append("g")
        .attr("id", "tooltipGroup");

      tooltipGroup
        .append("rect")
        .attr("width", tooltipWidth)
        .attr("height", tooltipHeight)
        .attr("fill", "white")
        .attr("id", "rectBackground")
        .attr("y", -tooltipHeight / 2 + 4)
        .attr("rx", "5")
        .style("box-shadow", "1px 1px 5px 1px black")
        .style("filter", "drop-shadow(0px 0px 3px rgba(0, 0, 0, 1)");

      tooltipGroup
        .append("text")
        .attr("id", "tooltip_upper_text")
        .attr("x", 7)
        .attr("font-size", 10)
        .text(data.categories[categoryIndex.get(this)]);

      let lowerText = tooltipGroup.append("text").attr("x", 7).attr("y", +15);

      lowerText
        .append("tspan")
        .attr("font-size", 18)
        .attr("font-weight", "bold")
        .attr("fill", colorScale[seriesIndex.get(this)])
        .text("• ");

      lowerText
        .append("tspan")
        .attr("id", "tooltip_lower_text")
        .attr("font-size", 12)
        .text(data.series[seriesIndex.get(this)].name);

      lowerText
        .append("tspan")
        .attr("font-size", 12)
        .attr("font-weight", "bold")
        .text(`: ${d}`);

      let upperTextElement = document.getElementById("tooltip_upper_text");
      let lowerTextElement = document.getElementById("tooltip_lower_text");
      let upperTextBoundingBox = upperTextElement.getBBox();
      let lowerTextBoundingBox = lowerTextElement.getBBox();

      // Get the dimensions of the bounding box
      let upperTextWidth = upperTextBoundingBox.width;

      let lowerTextWidth = lowerTextBoundingBox.width;

      let textWidth =
        upperTextWidth > lowerTextWidth + 50
          ? +upperTextWidth
          : +lowerTextWidth + 50;

      // Set the width and height of the background rectangle
      let rectBackground = document.getElementById("rectBackground");
      let tooltipGroupBackground = document.getElementById("tooltipGroup");
      rectBackground.setAttribute("width", textWidth + 20); // Add padding for better visibility

      let position_x =
        document.getElementById("grouped_horizontal_bar").offsetWidth -
          (spaceForLabels + x(d)) >
        textWidth
          ? spaceForLabels + x(d) + 4
          : spaceForLabels - rectBackground.getAttribute("width") - 4;

      let position_y =
        height > rectBackground.getAttribute("height") / 2
          ? height
          : rectBackground.getAttribute("height") / 2;
      tooltipGroupBackground.setAttribute(
        "transform",
        `translate(${position_x},${position_y})`
      );
    })
    .on("mouseout", function (d) {
      d3.select("g#tooltipGroup").remove();

      d3.selectAll(".rect").style("opacity", barOpacity);
      d3.select(this).style("cursor", "none");

      d3.select("#tooltip").remove();
    })
    .transition()
    .duration(1200)
    .attr("width", x);

  //Add line below bar

  d3.selectAll("g:has(.bar0)")
    .append("line")
    .attr("x1", 0)
    .attr("x2", chartWidth)
    .attr("y1", barHeight / 2)
    .attr("y2", barHeight / 2)
    .attr("stroke", "black")
    .attr("transform", `translate(0,${-barHeight - 1})`)
    .style("opacity", 0.2);

  // Add text label in bar
  bar
    .append("text")
    .attr("x", (d) => x(d) + 3)
    .attr("y", barHeight / 2)
    .attr("fill", "black")
    .style("font", "11px sans-serif")
    .style("font-weight", "bold")
    .attr("dy", ".35em")
    .text((d) => d)
    .attr("opacity", 0)
    .transition()
    .duration(1500)
    .attr("opacity", 1);

  // Draw labels
  bar
    .append("text")
    .attr("class", "label")
    .attr("text-anchor", "end")
    .style("font", "13px sans-serif")
    .attr("x", function (d) {
      return -10;
    })
    .attr("y", groupHeight / 2)
    .attr("dy", ".35em")
    .text(function (d, i) {
      if (i % data.series.length === 0) {
        var val = data.categories[Math.floor(i / data.series.length)];
        return trimText(val, 60);
      } else return "";
    })
    .append("title")
    .text(function (d, i) {
      if (i % data.series.length === 0) {
        var val =
          data.categories[Math.floor(i / data.series.length)] +
          (data.accepting_journal_issn
            ? ` - ( ISSN : ${
                data.accepting_journal_issn[Math.floor(i / data.series.length)]
              } ) ${data.p_journal_impactFactor ?`- ( IF : ${
                data.p_journal_impactFactor[Math.floor(i / data.series.length)]
              } )`:""}`
            : "");
        return val;
      } else return "";
    });
  function trimText(text, threshold) {
    if (text.length <= threshold) return text;
    return text.substr(0, threshold).concat("...");
  }

  const removeAxis = chart
    .append("g")
    .attr("class", "y axis")
    .attr(
      "transform",
      "translate(" + spaceForLabels + ", " + -gapBetweenGroups / 2 + ")"
    )
    .call(yAxis);

  const xAxisGroup = chart
    .append("g")
    .attr("class", "x axis")
    .attr(
      "transform",
      `translate(${spaceForLabels},${chartHeight + marginBottom})`
    )
    .call(xAxis);

  xAxisGroup
    .selectAll("text")
    .attr("font-size", "13px")
    .attr("transform", `translate(0,10)`);

  xAxisGroup.selectAll(".tick line").attr("opacity", 0);

  xAxisGroup.selectAll("path").attr("opacity", 0.2);

  removeAxis.selectAll("path,line").remove();

  // Draw legend
  let legendRectSize = 14,
    legendSpacing = 4;

  let legend = chart
    .selectAll(".legend")
    .data(data.series)
    .enter()
    .append("g")
    .attr("transform", function (d, i) {
      let height = legendRectSize + legendSpacing;
      let offset = -gapBetweenGroups / 2;
      let horz = spaceForLabels + chartWidth + 40 - legendRectSize;
      let vert = i * height - offset;
      return "translate(" + horz + "," + (vert + chartHeight / 2) + ")";
    });

  legend
    .append("circle")
    .attr("r", 6)
    .attr("cx", 0)
    .attr("cy", 5)
    .style("fill", function (d, i) {
      return color(i);
    })
    .style("stroke", function (d, i) {
      return color(i);
    });

  legend
    .append("text")
    .attr("class", "legend")
    .attr("x", legendRectSize + legendSpacing)
    .attr("y", legendRectSize - legendSpacing)
    .text(function (d) {
      return d.name;
    })
    .style("font", "12px sans-serif");

  return d3.select("#grouped_horizontal_bar > svg").node();
}

function GroupedHorizontalBar({ data, journalType }) {
  const dispatch = useDispatch();
  const [validContnet, setValidContent] = useState(true);
  const [svg, setSvg] = useState("");

  const graphName = (type) => {
    switch (type) {
      case "rejectingJournal":
        return "Rejecting Journals and Respective Citations";
      case "acceptingJournal":
        return "Accepting Journals and Respective Citations";
      case "subjectCategory":
        return "Subject Category and Respective Citations";
      default:
        return " ";
    }
  };

  const JournalsMessage = (type) => {
    switch (type) {
      case "rejectingJournal":
        return rejectingJournalsMessage;
      case "acceptingJournal":
        return acceptingJournalsMessage;
      case "subjectCategory":
        return subjectCategoryMessage;
      default:
        return " ";
    }
  };

  useEffect(() => {
    if (Object.keys(data).length) {
      if (data.categories && data.categories.length >= 1) {
        setValidContent(true);
        setSvg(loadGraph(data, dispatch, journalType));
      } else {
        setValidContent(false);
      }
    }
  }, [data]);

  return (
    <div className="my-3 card card-body">
      <h5>
        {/* {journalType === "rejectingJournal" ? "Rejecting" : "Accepting "}{" "}
        Journals and Respective Citations */}
        {graphName(journalType)}
        <TooltipButton
          tooltipMessage={
            JournalsMessage(journalType)
            // journalType === "rejectingJournal"
            //   ? rejectingJournalsMessage
            //   : acceptingJournalsMessage
          }
          placement="right"
          type={"string"}
          classes={"TooltipDiv"}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            size="sm"
            className="fs-14 ps-2 text-secondary"
          />
        </TooltipButton>
      </h5>
      <div className="mt-3 position-relative">
        {svg && <Graphdownload svg={svg} filename={toTitleCase(title)} />}
        {validContnet ? (
          <div className="chart-container_grouped_horizontal_bar">
            <div id="grouped_horizontal_bar" className=" chart"></div>
          </div>
        ) : (
          <NoDataFound />
        )}
      </div>
    </div>
  );
}

export default GroupedHorizontalBar;
