import React, { useState, useEffect } from "react";
import { title, toTitleCase } from "../../../Filter/Filter";
import ReactGA from "react-ga4";
import * as d3 from "d3";
import { useDispatch } from "react-redux";
import {
  setManuscriptModal,
  setGraphType,
} from "../../../../Redux/Action/filter.action";
import Graphdownload from "../Graphdonwload/Graphdownload";

import "./StackBarChart.scss";
import NoDataFound from "../../../NotFound/NoDataFound";

function loadGraph(data, type, dispatch) {
  const margin = { top: 20, right: 90, bottom: 5, left: 80 };
  let svgWidth = 1200;
  const svgHeight = 400;

  var element = d3.select(".chart-container").node();

  if (element) {
    const compWidth = element.getBoundingClientRect().width;
    svgWidth = compWidth < svgWidth ? svgWidth : compWidth;
    console.log("compWidth => ", compWidth);
  }
  let width = svgWidth - margin.left - margin.right;
  const height = svgHeight - margin.top - margin.bottom;
  let duration = 250;

  if (!d3.select("#stack_bar > svg").empty()) {
    d3.select("#stack_bar > svg").remove();
  }

  const barIndex = d3.local();
  const rectangleIndex = d3.local();

  /* Add SVG */
  let svg = d3
    .select("#stack_bar")
    .append("svg")
    .attr("viewBox", `0 0 ${svgWidth} ${svgHeight + 100}`);
  // .attr("width", svgWidth)
  // .attr("height", svgHeight + 100)

  const rejectingJournals = data.categories;
  const years = data.years;

  const series = years.map((year, index_year) => ({
    year,
    data: data.series.map((item) => item.data[index_year]),
  }));

  const x = d3
    .scaleBand()
    .domain(rejectingJournals)
    .range([0, width])
    .padding(0.1);

  const y = d3
    .scaleLinear()
    .domain([
      0,
      d3.max(
        rejectingJournals.map((_, i) => d3.sum(series.map((s) => s.data[i])))
      ),
    ])
    .range([height, 0])
    .nice()
    // .constant(10);

  const color = d3
    .scaleOrdinal()
    .domain(years.map((year) => year))
    .range(d3.schemeCategory10);

  const stack = d3
    .stack()
    .keys(years.map((year) => year))
    .value((d, key) => d[key]);

  const stackeddata = rejectingJournals.map((rejectingJournal, i) => {
    const entry = { rejectingJournal };
    series.forEach((s) => {
      entry[s.year] = s.data[i];
    });
    return entry;
  });

  const stackedseries = stack(stackeddata);

  const g = svg
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  g.selectAll("g")
    .data(stackedseries)
    .enter()
    .append("g")
    .attr("fill", (d) => color(d.key))
    .each(function (d, i) {
      rectangleIndex.set(this, i);
    })
    .selectAll("rect")
    .data((d) => d)
    .enter()
    .append("rect")
    .attr("x", (d) => x(d.data.rejectingJournal))
    .attr("y", (d) => y(d[1]))
    .attr("height", (d) => y(d[0]) - y(d[1])) // change for log scale != 0?d[0]:1
    .attr("width", x.bandwidth())
    .each(function (d, i) {
      barIndex.set(this, i);
    })
    .on("mouseover", function (event, d) {
      d3.select(this).style("cursor", "pointer");

      let height = y(d[1]);
      let tooltipWidth = 150;
      let tooltipHeight = 35;

      const tooltipGroup = d3
        .select("#stack_bar svg")
        .append("g")
        .attr("id", "tooltipGroup")
        // .style("text-anchor", "end")
        .attr(
          "transform",
          `translate(${x(d.data.rejectingJournal) + margin.left + 2},${
            y(d[1]) + 0
          })`
        );

      tooltipGroup
        .append("rect")
        .attr("width", tooltipWidth)
        .attr("height", tooltipHeight)
        .attr("fill", "white")
        .attr("id", "rectBackground")
        // .attr("x", spaceForLabels + x(d))
        .attr("y", -tooltipHeight / 2 + 4)
        .attr("rx", "5")
        .style("box-shadow", "1px 1px 5px 1px black")
        .style("filter", "drop-shadow(0px 0px 3px rgba(0, 0, 0, 1)");

      tooltipGroup
        .append("text")
        .attr("id", "tooltip_upper_text")
        .attr("x", 7)
        .attr("font-size", 10)
        // .text("• ")
        .attr("font-weight", "bold")
        .attr("fill", color(years[rectangleIndex.get(this)]))
        .text(years[rectangleIndex.get(this)]);

      let lowerText = tooltipGroup.append("text").attr("x", 7).attr("y", +15);

      lowerText
        .append("tspan")
        .attr("font-size", 18)
        .attr("font-weight", "bold");
      // .text("• ")
      // .attr("fill", color(data.year[rectangleIndex.get(this)]))

      lowerText
        .append("tspan")
        .attr("id", "tooltip_lower_text")
        .attr("font-size", 12)
        .text(data.series[barIndex.get(this)].name);

      lowerText
        .append("tspan")
        .attr("font-size", 12)
        .attr("font-weight", "bold")
        .text(
          `: ${data.series[barIndex.get(this)].data[rectangleIndex.get(this)]}`
        );

      let upperTextElement = document.getElementById("tooltip_upper_text");
      let lowerTextElement = document.getElementById("tooltip_lower_text");
      let upperTextBoundingBox = upperTextElement.getBBox();
      let lowerTextBoundingBox = lowerTextElement.getBBox();

      // Get the dimensions of the bounding box
      let upperTextWidth = upperTextBoundingBox.width;

      let lowerTextWidth = lowerTextBoundingBox.width;

      let textWidth =
        upperTextWidth > lowerTextWidth + 50
          ? +upperTextWidth
          : +lowerTextWidth + 50;

      // Set the width and height of the background rectangle
      let rectBackground = document.getElementById("rectBackground");
      let tooltipGroupBackground = document.getElementById("tooltipGroup");
      rectBackground.setAttribute("width", textWidth + 0); // Add padding for better visibility

      let shiftRight = 0;
      //   x( data.series[barIndex.get(this)].name) +
      //   margin.left
      // - rectBackground.getAttribute("width") / 2;
      // if (shiftRight < 0) {
      //   shiftRight = -shiftRight + 10;
      // }
      let position_x =
        document.getElementById("stack_bar").offsetWidth -
          (+x(data.series[barIndex.get(this)].name) + margin.left) >
          // - rectBackground.getAttribute("width") / 2
        textWidth
          ? +x(data.series[barIndex.get(this)].name) + margin.left >
            // - rectBackground.getAttribute("width") / 2
            0
            ? +x(data.series[barIndex.get(this)].name) + margin.left
            : // - rectBackground.getAttribute("width") / 2
              +x(data.series[barIndex.get(this)].name) +
              margin.left +
              // - rectBackground.getAttribute("width") / 2
              shiftRight
          : +x(data.series[barIndex.get(this)].name) +
            margin.left -
            rectBackground.getAttribute("width") / 2;

      let position_y =
        height > rectBackground.getAttribute("height") / 2
          ? height
          : rectBackground.getAttribute("height") / 2;

      tooltipGroupBackground.setAttribute(
        "transform",
        `translate(${position_x},${position_y})`
      );
    })
    .on("mouseout", () => {
      d3.select(this)
        .style("cursor", "none")
        .transition()
        .duration(duration)
        .selectAll(".text")
        .remove();

      d3.select("#tooltip").remove();

      d3.select("g#tooltipGroup").remove();
    });

  g.append("g")
    .attr("transform", `translate(0,${height + 0})`)
    .call(d3.axisBottom(x))
    .selectAll("text")
    .style("font-size", "14px")
    .call(wrapText, x.bandwidth());

  g.append("g").call(d3.axisLeft(y)).style("font-size", "14px");

  // Legend
  const legend = svg
    .append("g")
    .attr("transform", `translate(${width + margin.left + 20}, ${margin.top})`);

  legend
    .selectAll("circle")
    .data(years)
    .enter()
    .append("circle")
    .attr("cx", 0)
    .attr("cy", (d, i) => i * 20)
    .attr("r", 7)
    .style("fill", (d) => color(d));

  legend
    .selectAll("text")
    .data(years)
    .enter()
    .append("text")
    .attr("x", 24)
    .attr("y", (d, i) => i * 20)
    .attr("dy", ".35em")
    .style("text-anchor", "start")
    .text((d) => d);

  function wrapText(text, width) {
    text.each(function () {
      const text = d3.select(this);
      const words = text.text().split(/\s+/).reverse();
      let word;
      let line = [];
      let lineNumber = 0;
      const lineHeight = 1.1; // ems
      const y = text.attr("y");
      const dy = parseFloat(text.attr("dy"));
      let tspan = text
        .text(null)
        .append("tspan")
        .attr("x", 0)
        .attr("y", y)
        .attr("dy", dy + "em");

      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  }

  svg
    .append("text")
    .attr("class", "y label")
    .attr("text-anchor", "end")
    .attr("x", -((height - margin.top * 4) / 2))
    .attr("y", 10)
    .attr("dy", ".75em")
    .attr("transform", "rotate(-90)")
    .attr("font-size", "14px")
    .text(type + " Trend");

  // function trimText(text, threshold) {
  //   if (text.length <= threshold) return text;
  //   return text.substr(0, threshold).concat("...");
  // }

  return d3.select("#stack_bar > svg").node();
}

function StackBarChart({ data, activeBtn }) {
  const [validContnet, setValidContent] = useState(true);
  const [svg, setSvg] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(data).length && data.series && data.series.length >= 1) {
      setValidContent(true);
      setSvg(loadGraph(data, activeBtn, dispatch));
    } else {
      setValidContent(false);
    }
  }, [data]);

  return (
    <div className="mt-3  mainChartStackBar position-relative">
      {!validContnet && <NoDataFound />}

      {svg && <Graphdownload svg={svg} filename={toTitleCase(title)} />}
      <div className="chart-container">
        <div id="stack_bar"></div>
      </div>
    </div>
  );
}

export default StackBarChart;
