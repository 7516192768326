export const accessTypeMessage = <div>Gain detailed Insights into the rejection patterns based on access types.  <b>OA-</b> The OA Rejected Manuscripts. <b>Non-OA </b>- The Non-OA Rejected Manuscripts.  <b>Overall</b> - Total OA and Non-OA Rejected Manuscripts that got published. <b>With us </b>-  The OA and Non-OA Rejected Manuscripts that got published in our own journals. <b>Elsewhere</b> - The OA and Non-OA Rejected Manuscripts that got published in journals except ours. The graph and the grid depict the same result. <b>Note </b>- OA - Open Access, Non-OA - Non-Open Access</div>
export const publishersMessage = <div>Gain detailed Insights into the Rejected Manuscripts being accepted by other prestigious publishers. <b>The innermost circle</b>- Represents the name of the rejecting entity (not clickable). <b>Middle circle</b> -  Represents other prestigious publishers. <b>Outermost circle</b> - Represents the accepting journals of the same prestigious publishers. <b>Publication</b> - Depicts the graph by Publication Counts. <b>Citation</b> - Depicts the graph by Citation Counts.</div>
export const rejectingJournalsMessage = <div>Gain detailed Insights into the Rejecting Journals of Manuscripts. The graph represents the total number of Manuscripts rejected by a specific journal. <b>Note</b> - The graph depicts the results by Publication Count.</div>
export const acceptingJournalsMessage = <div>Gain detailed Insights into the Accepting Journals of Manuscripts. The graph represents the total number of Manuscripts accepted by a specific journal. Hover over the journal name on the graph to see the ISSN number. <b>Note</b> - The graph depicts the results by Publication Count.</div>
export const rejectingVsAcceptingJournalMessage = <div>Gain detailed Insights into the various Accepting Journals of one Specific Rejecting Journal. <b>Each tile</b> -  Represents the Rejecting Journal and various Accepting Journals of the same. <b>Publication</b> - Depicts the graph by Publication Counts. <b>Citation</b> - Depicts the graph by Citation Counts.</div>
export const timeToPublicationMessage = <div>Gain detailed Insights by time i.e. how long a Rejected Manuscript took to get published. <b>Each colour line</b> -  Represents a different Accepting Journal. <b>Dots</b> -  Represent the time frame in which multiple Rejected Manuscripts are accepted by a journal. </div>
export const acceptingVsSubjectCategoriesMessage= <div>Gain detailed Insights into a Specific Accepting Journal and the various Subject Categories inside it. <b>Each tile</b> - Represents the Accepting Journal and the various Subject Categories within. <b>Publication</b> - Depicts the graph by Publication Counts. <b>Citation</b> - Depicts the graph by Citation Counts.</div>
export const averageCitationMessage= <div>Gain detailed Insights by Average Citations i.e. the average citations for a specific year for a particular Rejecting Journal. <b>Each colour line</b> - Represents a Rejecting Journal. <b>Dots</b> - Represent the Average Citations for that year.</div>
export const subjectCategoryMessage = <div>Gain detailed Insights into a Specific Subject Category. The graph represents the total Rejected Manuscripts, total Publications and total Citations. <b>Note</b> - The graph depicts the results by Publication Count.</div>
export const rejectionAndPublicationTrend = <div>Sample text for Rejection and Publication Trend</div>
export const journalCascadeMessage =<div>Gain detailed insights into the year-wise published data, bifurcated into published 'With us' & Published 'Elsewhere.' <b>Note</b> - Hovering over the graph shows a tooltip that shows the year of publication along with where the manuscript was published. The Y-axis <i>(highest Y coordinate)</i> of the graph represents the highest total of the manuscripts published within a journal. The X-axis represents the rejected journals.</div>