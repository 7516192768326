import React, { useState, useEffect } from "react";
import { title, toTitleCase } from "../../../Filter/Filter";
import ReactGA from "react-ga4";
import * as d3 from "d3";
import { useDispatch } from "react-redux";
import {
  setManuscriptModal,
  setGraphType,
} from "../../../../Redux/Action/filter.action";
import Graphdownload from "../Graphdonwload/Graphdownload";

import "./DoubleLayerStackBarChart.scss";
import NoDataFound from "../../../NotFound/NoDataFound";
import TooltipButton from "../../../TooltipButton/TooltipButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { journalCascadeMessage } from "../../../TooltipButton/TooltipMessage";

function loadGraph(data, dispatch, journalType) {

  //SAMPLE DATA FORMATE FOR DOUBLE HEADER TABLE
  // const data = {
  //   categories: [
  //     "IEEE Access",
  //     "IEEE Sensors Journal",
  //     "Transactions on Industrial Electronics",
  //     "IEEE Transactions on Intelligent Vehicles",
  //     "IEEE Transactions on Systems, Man and Cybernetics: Systems",
  //     "IEEE Transactions on Circuits and Systems for Video Technology",
  //     "IEEE Transactions on Neural Networks and Learning Systems",
  //     "IEEE Journal of Biomedical and Health Informatics",
  //     "IEEE Transactions on Vehicular Technology",
  //     "IEEE Transactions on Cybernetics",
  //   ],
  //   years: ["2017", "2018", "2019", "2020", "2021", "2022", "2023"],
  //   series: [
  //     {
  //       name: "IEEE Access",
  //       data: [0, 0, 0, 0, 6311, 11678, 16871],
  //       withUs: [0, 0, 0, 0, 2311, 4678, 6871],
  //       elseWere: [0, 0, 0, 0, 4000, 7000, 10000],
  //     },
  //     {
  //       name: "IEEE Sensors Journal",
  //       data: [0, 0, 0, 0, 757, 1729, 4483],
  //       withUs: [0, 0, 0, 0, 257, 729, 1483],
  //       elseWere: [0, 0, 0, 0, 500, 1000, 3000],
  //     },
  //     {
  //       name: "Transactions on Industrial Electronics",
  //       data: [0, 0, 0, 0, 838, 1862, 3413],
  //       withUs: [0, 0, 0, 0, 538, 862, 1413],
  //       elseWere: [0, 0, 0, 0, 300, 1000, 2000],
  //     },
  //     {
  //       name: "IEEE Transactions on Intelligent Vehicles",
  //       data: [0, 0, 0, 0, 28, 844, 4017],
  //       withUs: [0, 0, 0, 0, 14, 544, 1017],
  //       elseWere: [0, 0, 0, 0, 14, 300, 3000],
  //     },
  //     {
  //       name: "IEEE Transactions on Systems, Man and Cybernetics: Systems",
  //       data: [0, 0, 0, 15, 566, 1446, 3076],
  //       withUs: [0, 0, 0, 15, 166, 446, 1076],
  //       elseWere: [0, 0, 0, 0, 400, 1000, 2000],
  //     },
  //     {
  //       name: "IEEE Transactions on Circuits and Systems for Video Technology",
  //       data: [0, 0, 0, 0, 580, 1481, 3666],
  //       withUs: [0, 0, 0, 0, 180, 481, 2666],
  //       elseWere: [0, 0, 0, 0, 400, 1000, 1000],
  //     },
  //     {
  //       name: "IEEE Transactions on Neural Networks and Learning Systems",
  //       data: [0, 0, 0, 6, 615, 2468, 4877],
  //       withUs: [0, 0, 0, 0, 215, 468, 1877],
  //       elseWere: [0, 0, 0, 6, 400, 2000, 3000],
  //     },
  //     {
  //       name: "IEEE Journal of Biomedical and Health Informatics",
  //       data: [0, 0, 0, 2, 676, 1508, 3330],
  //       withUs: [0, 0, 0, 1, 176, 508, 1330],
  //       elseWere: [0, 0, 0, 1, 500, 1000, 2000],
  //     },
  //     {
  //       name: "IEEE Transactions on Vehicular Technology",
  //       data: [0, 0, 0, 1, 642, 1524, 3413],
  //       withUs: [0, 0, 0, 1, 242, 524, 1413],
  //       elseWere: [0, 0, 0, 0, 400, 1000, 2000],
  //     },
  //     {
  //       name: "IEEE Transactions on Cybernetics",
  //       data: [0, 0, 0, 0, 682, 1451, 3044],
  //       withUs: [0, 0, 0, 0, 382, 451, 1044],
  //       elseWere: [0, 0, 0, 0, 300, 1000, 2000],
  //     },
  //   ],
  // };



  const margin = { top: 20, right: 30, bottom: 5, left: 80 };
  let svgWidth = 1200;
  const svgHeight = 450;

  var element = d3.select(".chart-container").node();

  if (element) {
    const compWidth = element.getBoundingClientRect().width;
    svgWidth = compWidth < svgWidth ? svgWidth : compWidth;
  }
  let width = svgWidth - margin.left - margin.right;
  const height = svgHeight - margin.top - margin.bottom;
  let duration = 250;

  if (!d3.select("#double_layer_stack_bar > svg").empty()) {
    d3.select("#double_layer_stack_bar > svg").remove();
  }

  const barIndex = d3.local();
  const rectangleIndex = d3.local();

  /* Add SVG */
  let svg = d3
    .select("#double_layer_stack_bar")
    .append("svg")
    .attr("viewBox", `0 0 ${svgWidth} ${svgHeight + 100}`);
  // .attr("width", svgWidth)
  // .attr("height", svgHeight + 100)

  const rejectingJournals = data.categories;
  const years = data.years;

  const series = years.map((year, index_year) => ({
    year,
    data: data.series.map((item) => item.data[index_year]),
    with_us: data.series.map((item) => item.with_us[index_year]),
    elsewhere: data.series.map((item) => item.elsewhere[index_year]),
  }));

  const x = d3
    .scaleBand()
    .domain(rejectingJournals)
    .range([0, width])
    .padding(0.1);

  const y = d3
    .scaleLinear()
    .domain([
      0,
      d3.max(
        rejectingJournals.map((_, i) => d3.sum(series.map((s) => s.data[i])))
      ),
    ])
    .range([height, 0])
    .nice();

  const color = d3
    .scaleOrdinal()
    .domain(years.map((year) => year))
    .range(d3.schemeCategory10);

  const stack = d3
    .stack()
    .keys(years.map((year) => year))
    .value((d, key) => d[key]);

  // const smallStack = d3.stack()

  const stackedData = rejectingJournals.map((rejectingJournal, i) => {
    const entry = { rejectingJournal };
    series.forEach((s) => {
      entry[s.year] = s.data[i];
    });
    return entry;
  });

  const smallStackedData = rejectingJournals.map((rejectingJournal, i) => {
    const entry = { rejectingJournal };
    series.forEach((s) => {
      entry[s.year] = s.elsewhere[i];
    });
    return entry;
  });

  const stackedSeries = stack(stackedData);
  const smallStackedSeries = stack(smallStackedData);

  const g = svg
    .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

  const bar = g
    .selectAll("g")
    .data(stackedSeries)
    .enter()
    .append("g")
    .attr("fill", (d) => {
      return "#FF8F00"; 
    })
    .attr("stroke", "black")
    .attr("stroke-width", 2)
    .each(function (d, i) {
      rectangleIndex.set(this, i);
    });

  bar
    .selectAll("rect")
    .data((d) => d)
    .enter()
    .append("rect")
    .attr("x", (d) => x(d.data.rejectingJournal))
    .attr("y", (d) => y(d[1]))
    .attr("height", (d) => y(d[0]) - y(d[1])) 
    .attr("width", x.bandwidth())
    .each(function (d, i) {
      let thisValue = this;
      const smallY_axis = d3
        .scaleLinear()
        .domain([0, data.series[i].data[rectangleIndex.get(this)]])
        .range([y(d[0]) - y(d[1]), 0]); 

      bar
        .append("rect")
        .attr("x", x(d.data.rejectingJournal) + 1)
        .attr("y", y(d[1]) + 1)
        .attr("height", (_, index) => {
          const totalHeight = y(d[0]) - y(d[1]);
          const elsewereHeight =
            smallY_axis(smallStackedSeries[rectangleIndex.get(this)][i][0]) -
            smallY_axis(smallStackedSeries[rectangleIndex.get(this)][i][1]);

          const heightOffset =
            Math.abs(Math.round(totalHeight - elsewereHeight)) == 0 ? 2 : 8;
          return totalHeight - heightOffset;
        
        }) 
        .attr("width", x.bandwidth() - 2)
        .attr("fill", "#40A2D8") //#40A2D8
        .attr("stroke", "black")
        .attr("stroke-width", 4)
        .attr("stroke", "black")
        .attr("stroke-opacity", 0)
        .on("click", function (event) {
          clickFunction(d, "elsewhere", thisValue);
        })
        
        .on("mouseover", function (event) {
          mouseoverFunction(d, "elsewhere", thisValue);
        })
        .on("mouseout", function () {
          mouseoutFunction(thisValue);
        });

      barIndex.set(this, i);
    })
    .on("click", function (event, d) {
      clickFunction(d, "with_us", this);
    })
   
    .on("mouseover", function (event, d) {
      mouseoverFunction(d, "with_us", this);
      
    })
    .on("mouseout", function () {
      mouseoutFunction(this);
    });

  //CLICK FUNCTION
  function clickFunction(d, acceptedBy, thisValue) {
     ReactGA.event({
            category: toTitleCase(title),
            action: `Stack Bar Graph clicked`,
            label: `s_journal= ${
              d.data.rejectingJournal
            }, filterStatus= ${acceptedBy == "with_us" ? "Internal" : "External"}`,
          });
    dispatch(
      setGraphType({
        title: `${d.data.rejectingJournal} > ${
          years[rectangleIndex.get(thisValue)]
        }`,
        graphType: {
          graphType: journalType,
          s_journal: d.data.rejectingJournal,
          filterStatus: acceptedBy == "with_us" ? "Internal" : "External",
          s_rejection_date: years[rectangleIndex.get(thisValue)],
        },
      })
    );
    dispatch(setManuscriptModal(true));
  }

  // MOUSEOUT FUNCTION
  function mouseoutFunction() {
    d3.select(this)
      .style("cursor", "none")
      .transition()
      .duration(duration)
      .selectAll(".text")
      .remove();

    d3.select("#tooltip").remove();

    d3.select("g#tooltipGroup").remove();
  }

  // MOUSEOVER FUNCTION
  function mouseoverFunction(d, acceptedBy, thisValue) {
    d3.select(this).style("cursor", "pointer");

    let height = y(d[1]);
    let tooltipWidth = 150;
    let tooltipHeight = 35;

    const tooltipGroup = d3
      .select("#double_layer_stack_bar svg")
      .append("g")
      .attr("id", "tooltipGroup")
      // .style("text-anchor", "end")
      .attr(
        "transform",
        `translate(${x(d.data.rejectingJournal) + margin.left + 2},${
          y(d[1]) + 0
        })`
      );

    tooltipGroup
      .append("rect")
      .attr("width", tooltipWidth)
      .attr("height", tooltipHeight)
      .attr("fill", "white")
      .attr("id", "rectBackground")
      // .attr("x", spaceForLabels + x(d))
      .attr("y", -tooltipHeight / 2 + 4)
      .attr("rx", "5")
      .style("box-shadow", "1px 1px 5px 1px black")
      .style("filter", "drop-shadow(0px 0px 3px rgba(0, 0, 0, 1)");

    tooltipGroup
      .append("text")
      .attr("id", "tooltip_upper_text")
      .attr("x", 7)
      .attr("font-size", 10)
      // .text("• ")
      .attr("font-weight", "bold")
      .attr("fill", "black") 
      .text(years[rectangleIndex.get(thisValue)]);

    let lowerText = tooltipGroup
      .append("text")
      .attr("x", 7)
      .attr("y", +15)
      .attr("id", "tooltip_lower_text");

    lowerText.append("tspan").attr("font-size", 18).attr("font-weight", "bold");
   

    lowerText
      .append("tspan")
      // .attr("id", "tooltip_lower_text")
      .attr("font-size", 12)
      .text(data.series[barIndex.get(thisValue)].name);

    lowerText
      .append("tspan")
      .attr("font-size", 12)
      .attr("font-weight", "bold")
      .text(
        `: ${
          data.series[barIndex.get(thisValue)][acceptedBy][
            rectangleIndex.get(thisValue)
          ]
        }`
      );

    lowerText
      .append("tspan")
      .attr("font-size", 12)
      // .attr("font-weight", "bold")
      .text(` (${acceptedBy == "with_us" ? "With us" : "Elsewere"})`);

    let upperTextElement = document.getElementById("tooltip_upper_text");
    let lowerTextElement = document.getElementById("tooltip_lower_text");
    let upperTextBoundingBox = upperTextElement.getBBox();
    let lowerTextBoundingBox = lowerTextElement.getBBox();

    // Get the dimensions of the bounding box
    let upperTextWidth = upperTextBoundingBox.width;

    let lowerTextWidth = lowerTextBoundingBox.width;

    let textWidth =
      upperTextWidth > lowerTextWidth + 10
        ? +upperTextWidth
        : +lowerTextWidth + 10;

    // Set the width and height of the background rectangle
    let rectBackground = document.getElementById("rectBackground");
    let tooltipGroupBackground = document.getElementById("tooltipGroup");
    rectBackground.setAttribute("width", textWidth + 0); // Add padding for better visibility

    let shiftRight = 0;
    //   x( data.series[barIndex.get(this)].name) +
    //   margin.left
    // - rectBackground.getAttribute("width") / 2;
    // if (shiftRight < 0) {
    //   shiftRight = -shiftRight + 10;
    // }
    let position_x =
      document.getElementById("double_layer_stack_bar").offsetWidth -
        (+x(data.series[barIndex.get(thisValue)].name) + margin.left) >
      // - rectBackground.getAttribute("width") / 2
      textWidth
        ? +x(data.series[barIndex.get(thisValue)].name) + margin.left >
          // - rectBackground.getAttribute("width") / 2
          0
          ? +x(data.series[barIndex.get(thisValue)].name) + margin.left
          : // - rectBackground.getAttribute("width") / 2
            +x(data.series[barIndex.get(thisValue)].name) +
            margin.left +
            // - rectBackground.getAttribute("width") / 2
            shiftRight
        : +x(data.series[barIndex.get(thisValue)].name) +
          margin.left -
          rectBackground.getAttribute("width") / 2;

    let position_y =
      height > rectBackground.getAttribute("height") / 2
        ? height
        : rectBackground.getAttribute("height") / 2;

    tooltipGroupBackground.setAttribute(
      "transform",
      `translate(${position_x},${position_y})`
    );
  }

  g.append("g")
    .attr("transform", `translate(0,${height + 0})`)
    .call(d3.axisBottom(x))
    .selectAll("text")
    .style("font-size", "14px")
    .call(wrapText, x.bandwidth());

  g.append("g").call(d3.axisLeft(y)).style("font-size", "14px");

  // LEGEND
  // const legend = svg
  //   .append("g")
  //   .attr("transform", `translate(${width + margin.left + 20}, ${margin.top})`);

  // legend
  //   .selectAll("circle")
  //   .data(years)
  //   .enter()
  //   .append("circle")
  //   .attr("cx", 0)
  //   .attr("cy", (d, i) => i * 20)
  //   .attr("r", 7)
  //   .style("fill", (d) => color(d));

  // legend
  //   .selectAll("text")
  //   .data(years)
  //   .enter()
  //   .append("text")
  //   .attr("x", 24)
  //   .attr("y", (d, i) => i * 20)
  //   .attr("dy", ".35em")
  //   .style("text-anchor", "start")
  //   .text((d) => d);

  function wrapText(text, width) {
    text.each(function () {
      const text = d3.select(this);
      const words = text.text().split(/\s+/).reverse();
      let word;
      let line = [];
      let lineNumber = 0;
      const lineHeight = 1.1; // ems
      const y = text.attr("y");
      const dy = parseFloat(text.attr("dy"));
      let tspan = text
        .text(null)
        .append("tspan")
        .attr("x", 0)
        .attr("y", y)
        .attr("dy", dy + "em");

      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  }

  //Y-axis text
  svg
    .append("text")
    .attr("class", "y label")
    .attr("text-anchor", "end")
    .attr("x", -((height - margin.top * 4) / 2))
    .attr("y", 10)
    .attr("dy", ".75em")
    .attr("transform", "rotate(-90)")
    .attr("font-size", "14px")
    .text("Publication Count");

     //X-axis text
  svg
  .append("text")
  .attr("class", "x label")
  // .attr("text-anchor", "end")
  .attr("x", (svgWidth - margin.left - margin.right)/2)
  .attr("y", svgHeight + 85)
  .attr("dy", ".75em")
  // .attr("transform", "rotate(-90)")
  .attr("font-size", "14px")
  .text("Rejecting Journal");

  // function trimText(text, threshold) {
  //   if (text.length <= threshold) return text;
  //   return text.substr(0, threshold).concat("...");
  // }

  return d3.select("#double_layer_stack_bar > svg").node();
}

function DoubleLayerStackBarChart({ data, journalType }) {
  const dispatch = useDispatch();
  const [validContnet, setValidContent] = useState(true);
  const [svg, setSvg] = useState("");

  const graphName = (type) => {
    switch (type) {
      case "journalCascade":
        return "Journal Cascade";
      default:
        return " ";
    }
  };

  const JournalsMessage = (type) => {
    switch (type) {
      case "journalCascade":
        return journalCascadeMessage;

      default:
        return " ";
    }
  };

  // console.log("Graph data from stack bar =>", data);

  useEffect(() => {
    //     if (Object.keys(data).length && data.series && data.series.length >= 1) {
    //       setValidContent(true);
    setSvg(loadGraph(data, dispatch, journalType));
    //     } else {
    //       setValidContent(false);
    //     }
  }, [data]);

  return (
    <div className="my-3 card card-body">
      <h5>
        {/* {journalType === "rejectingJournal" ? "Rejecting" : "Accepting "}{" "}
        Journals and Respective Citations */}
        {graphName(journalType)}
        <TooltipButton
          tooltipMessage={
            JournalsMessage(journalType)
            // journalType === "rejectingJournal"
            //   ? rejectingJournalsMessage
            //   : acceptingJournalsMessage
          }
          placement="right"
          type={"string"}
          classes={"TooltipDiv"}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            size="sm"
            className="fs-14 ps-2 text-secondary"
          />
        </TooltipButton>
      </h5>
      <div className="mt-3  mainChartDoubleLayerStackBar position-relative">
        {!validContnet && <NoDataFound />}

        {svg && <Graphdownload svg={svg} filename={toTitleCase(title)} />}
        <div className="chart-container">
          <div id="double_layer_stack_bar"></div>
        </div>
      </div>
    </div>
  );
}

export default DoubleLayerStackBarChart;
