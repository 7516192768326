import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./TooltipStyle.scss";
const TooltipButton = ({
  children,
  type = "array",
  key,
  placement = "top",
  tooltipMessage,
  classes,
}) => {
  return (
    <OverlayTrigger
      key={key}
      placement={placement}
      overlay={
        type === "array" ? (
          <Tooltip id={key}>
            {tooltipMessage.map((item) => (
              <span>{item}, </span>
            ))}
          </Tooltip>
        ) : (
          <Tooltip id={key} className={classes}>
            {tooltipMessage}
          </Tooltip>
        )
      }
    >
      <span className="cursor-pointer">{children}</span>
    </OverlayTrigger>
  );
};

export default TooltipButton;
